import React from "react";
import { NavLink, Link } from "react-router-dom";
import { BiCube } from "react-icons/bi";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../../assets/css/Footer/footer.css";

const Footer = (props) => {
  return (
    <footer class="footer ">
      <div className="container-fluid">
        <nav className="nav pt-5">
          <ul className="footer_titles text-center">
            <li>
              <NavLink className="header_Navlink" exact to="/">
              {props.strings["text_0"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/rooms" className="header_Navlink">
                {props.strings["text_2"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact className="header_Navlink" to="/meeting_events">
                {props.strings["text_3"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact className="header_Navlink" to="/restaurant">
                {props.strings["text_4"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact className="header_Navlink" to="/spa_fitness">
                {props.strings["text_5"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact className="header_Navlink" to="/gallery">
                {props.strings["text_6"]}
              </NavLink>
            </li>
            <li>
              <NavLink exact className="header_Navlink" to="/contact">
                {props.strings["text_7"]}
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="row m-4 ">
          <div className="col-lg-4 text-center">
            <a
              href="https://www.facebook.com/crowneplazaankarahotel/"
              target="_blank"
            >
              <FaFacebookSquare className="social_media_icon" />
            </a>

            <a
              href="https://www.instagram.com/crowneplazaankara/"
              target="_blank"
            >
              <FaInstagram className="social_media_icon" />
            </a>
            <a
              href="https://tr.linkedin.com/company/crowne-plaza-ankara"
              target="_blank"
            >
              {" "}
              <FaLinkedin className="social_media_icon" />
            </a>
            <a href="https://twitter.com/CrownePlazaANK" target="_blank">
              <FaTwitter className="social_media_icon" />
            </a>
            <div className="footer_address pt-3">
              <h6>Ankara | Türkiye</h6>
              <h6>
                {" "}
                <a
                  className="footer_mail_link"
                  href="mailto: info@crowneplazaankara.com.tr"
                  target="_blank"
                >
                  info@crowneplazaankara.com.tr
                </a>
              </h6>
              <h6>
                <a className="pink_link" href="tel:+03123200000">
                  {" "}
                  +90 312 320 00 00
                </a>
              </h6>
            </div>
          </div>
          <div className="col-lg-4 d-flex justify-content-center">
            <Link to="/">
              {" "}
              <img
                className="footer_logo"
                src="img/logo/crowne_logo.svg"
              />{" "}
            </Link>
          </div>
          <div className="col-lg-4 footer_kvkk d-flex justify-content-end flex-column">
            <h6>
              <a
                  className="link_osi pink_link"
                  href="https://www.crowneplazaankara.com.tr/aydinlatma-metni.pdf"
                  target="_blank"
                >
                  {props.strings["text_101"]}{" "}
                </a>
                <a
                  className="link_osi pink_link"
                  href="https://www.crowneplazaankara.com.tr/kvkk-form.pdf"
                  target="_blank"
                >
                  | {props.strings["text_102"]}{" "} 
                </a>
            </h6>
            <h6>
              Copyright © 2023 CROWNE PLAZA | Designed by{" "}
              <span>
                <a
                  className="link_osi pink_link"
                  href="https://osireklamajansi.com/"
                  target="_blank"
                >
                  Osicrew
                </a>
              </span>
            </h6>
          </div>
        </div>
        <div className="row  ">
          <div className="d-flex justify-content-center">
            {" "}
            <h6 className="text-center istirak">{props.strings["text_116"]}</h6>
          </div>
        </div>
      </div>
      <div className="container ihg_header_div_container">
        <div className="row">
          <div className="col-lg-12 ihg-hotels-resort-section">
            <div className="ihg_header_div">
              {" "}
              <img
                className="footer_swiper_img_ihg_header footer_swiper_img"
                src="img/ihg_son.png"
              />
            </div>
            <div className="ihg-hotels-resort-section">
              {" "}
              <img className="footer_swiper_img" src="img/footer_logo/1.png" />
              <img className="footer_swiper_img" src="img/footer_logo/2.png" />
              <img className="footer_swiper_img" src="img/footer_logo/3.png" />
              <img className="footer_swiper_img" src="img/footer_logo/4.png" />
              <img className="footer_swiper_img" src="img/footer_logo/5.png" />
              <img className="footer_swiper_img" src="img/footer_logo/6.png" />
              <img className="footer_swiper_img" src="img/footer_logo/7.png" />
              <img className="footer_swiper_img" src="img/footer_logo/8.png" />
              <img className="footer_swiper_img" src="img/footer_logo/9.png" />
              <img className="footer_swiper_img" src="img/footer_logo/10.png" />
              <img className="footer_swiper_img" src="img/footer_logo/11.png" />
              <img className="footer_swiper_img" src="img/footer_logo/12.png" />
              <img className="footer_swiper_img" src="img/footer_logo/13.png" />
              <img className="footer_swiper_img" src="img/garner.png" />
              <img className="footer_swiper_img" src="img/footer_logo/14.png" />
              <img className="footer_swiper_img" src="img/footer_logo/15.png" />
              <img className="footer_swiper_img" src="img/footer_logo/16.png" />
              <img className="footer_swiper_img" src="img/footer_logo/17.png" />
              <img className="footer_swiper_img" src="img/footer_logo/18.png" />
            </div>
            <div className="ihg_reward_div">
              {" "}
              <img
                className="footer_swiper_img footer_swiper_ihg_reward"
                src="img/footer_logo/ihg_reward.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className=" pb-5">
          <a
            className="d-flex justify-content-center"
            href="https://www.ihg.com/content/gb/en/customer-care/bwc-lp"
            target="_blank"
          >
            {" "}
            <img
              className="footer_best_price"
              src="/img/best_quarant.png"
            />
          </a>
        </div>
      </div>

      {/* <div className="row justify-content-center">
        <div className="">
          <div className="ihg_carousel_left">
            <div className="row">
              <div className="footer_logo_section">
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/1.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/2.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/3.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/4.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/5.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/6.png"
                />{" "}
              </div>
            </div>{" "}
            <div className="row">
              <div className="footer_logo_section">
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/7.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/8.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/9.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/10.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/11.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/12.png"
                />{" "}
              </div>
            </div>
            <div className="row">
              <div className="footer_logo_section">
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/13.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/14.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/15.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/16.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/17.png"
                />{" "}
                <img
                  className="footer_swiper_img"
                  src="img/footer_logo/18.png"
                />
              </div>
            </div>
          </div>

          <div className="ihg_carousel_logo">
            <img src="img/footer_logo/ihg_header.png" />
          </div>
        </div>
      </div> */}
    </footer>
  );
};

Footer.propTypes = {
  dispatch: PropTypes.func,
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(Footer));
